<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <CCard>
          <CCardHeader>
            Usuários
            <CCol col=11 class="d-inline-block text-right">
              <CButton
                size="sm"
                color="success"
                @click="newUser" 
              >
                <span>Criar usuário</span>
              </CButton>
            </CCol>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              hover
              striped
              :items="items"
              :fields="fieldsArray"
              :items-per-page="perPage"
              @row-clicked="rowClicked"
              :pagination="$options.paginationProps"
              index-column
              clickable-rows
            >
              <template #username="data">
                <td>
                  <strong>{{data.item[0].username}}</strong>
                </td>
              </template>
              <template #email="data">
                <td>
                  <strong>{{data.item[0].email}}</strong>
                </td>
              </template>
              <template #role="data">
                <td>
                  <strong>{{data.item[0].role}}</strong>
                </td>
              </template>
            
              
            </CDataTable>
          </CCardBody>
        </CCard>
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import Users from "@/services/UserDataService";
// import usersData from './UsersData'
export default {
  name: 'Users',
  data: () => {
    return {
      items: [],
      fieldsArray: [
        { key: 'username', label: 'Nome', sorter: 1 },
        { key: 'email', label: 'E-mail' },
        { key: 'role', label: 'Permissão' }
      ],
      perPage: 6,
      largeModal: false,
      options: ['admin', 'moderator'],
      newUsername: '',
      newEmail: '',
      newPassword: '',
      newRole: '',
    }
  },
  paginationProps: {
    align: 'center',
    doubleArrows: false,
    previousButtonHtml: 'prev',
    nextButtonHtml: 'next'
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
    showModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR');
      }

      return false;
    },
    showAdminOrModeratorBoard() {
      if (this.currentUser && this.currentUser.roles) {
        return this.currentUser.roles.includes('ROLE_MODERATOR') || this.currentUser.roles.includes('ROLE_ADMIN');
      }

      return false;
    },
  },
  methods: {
    rowClicked (item, index) {
      this.$router.push({path: "users/"+ item[0].id})
    },
    newUser (){
      this.$router.push({path: 'users/new'})
    }
  },
  mounted () {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
    Users.getAll()
      .then(response => {
        this.items = response.data;

      })
      .catch(e => {
        alert(e);
      });
  }
}
</script>
